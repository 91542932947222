/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getApiUrl, isAuthenticated } from "../../../utils/AuthUtils"; // Import the apiUrl  function
import { TSLogoDark, DefaultCompanyLogo } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import axios from "axios";
import Footer from "../../layout/Footer";
import ButtonLoader from "../../../components/ButtonLoader";
import Roles from "../Administration/Roles/Roles";
// import { login } from "../../../user";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Email is required")
    .email("Please enter a valid email address")
    .max(255, "Email must be at most 255 characters"),
  password: Yup.string()
    .trim()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters"),
});

const Login = () => {
  const details = localStorage.getItem("loginDetails");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const loginData = JSON.parse(details);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [entityLogo, setEntityLogo] = useState(TSLogoDark);
  const [entityName, setEntityName] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(getApiUrl() + "/api/login", {
        email: data.email.trim(),
        password: data.password,
      })
      .then(function (response) {
        setLoading(false);
        if (response.status === 200) {
          const success = response.data.success;
          if (success === 1) {
            const userData = response.data.data;
            const token = response.data.token;
            const role = response.data.role;
            const modules = response.data.modules;
            const categories = response.data.categories;
            let roleDefault = "/dashboard";
            if (["super-admin", "sales-head", "sales-rep"].includes(role)) {
              if (categories && categories.length > 0) {
                const categorySlug = categories[0].category_slug;
                roleDefault = "/role-dashboard/" + categorySlug;
              }
            }

            const redirectPath = location.state?.from?.pathname || roleDefault;
            setUserData(userData);
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("categoryData", JSON.stringify(categories));
            localStorage.setItem(
              "userModulePermissions",
              JSON.stringify(modules)
            );
            localStorage.setItem("loginUrl", "/login");
            localStorage.setItem("token", token);
            localStorage.setItem("role", role);
            localStorage.setItem("colorschema", "blue");
            localStorage.setItem("layout", "vertical");
            localStorage.setItem("layoutwidth", "fluid");
            localStorage.setItem("layoutpos", "fixed");
            localStorage.setItem("topbartheme", "light");
            localStorage.setItem("layoutSized", "lg");
            localStorage.setItem("layoutStyling", "default");
            localStorage.setItem("layoutSidebarStyle", "light");
            dispatch(login(userData));
            // const redirectPath = localStorage.getItem("redirect_path");
            navigate(redirectPath);
          } else {
            setLoginErrorMessage(response.data.data);
            setLoginError(true); // Email is not registered
            navigate("/login");
            setTimeout(() => {
              setLoginErrorMessage("");
              setLoginError(false);
            }, 10000);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        let errorMessage = "Unable to connect at the moment, please try again.";
        if (error.response.data.errors) {
          errorMessage = error.response.data.errors;
        }
        setLoginErrorMessage(errorMessage);
        setLoginError(true); // Email is not registered
        setTimeout(() => {
          setLoginErrorMessage("");
          setLoginError(false);
        }, 10000);
        navigate("/login");
      });
  };

  const updatedEntityData = () => {
    if (
      localStorage.getItem("entity_name") &&
      localStorage.getItem("entity_name") !== "undefined"
    ) {
      setEntityLogo(localStorage.getItem("entity_logo"));
      setEntityName(localStorage.getItem("entity_name"));
    }
    let roleDefault = "/dashboard";
    if (["super-admin", "sales-head", "sales-rep"].includes(localStorage.getItem("role"))) {
      const categories = JSON.parse(localStorage.getItem("categoryData"));
      if (categories && categories.length > 0) {
        const categorySlug = categories[0].category_slug;
        roleDefault = "/role-dashboard/" + categorySlug;
      }
    }
    if (isAuthenticated()) {
      const redirectPath = location.state?.from?.pathname || roleDefault;
      return navigate(redirectPath);
    }
    setTimeout(() => {
      if (!entityName) {
        updatedEntityData();
      }
    }, 100);
  };

  useEffect(() => {
    if (!entityName) {
      updatedEntityData();
    }
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* <Link to="/job-list" className="btn btn-primary apply-btn">
              Apply Job
            </Link> */}
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <img
                  src={entityLogo}
                  alt="Company Logo"
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop in case the default image also fails
                    e.target.src = DefaultCompanyLogo; // Set the default image on error
                  }}
                />
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h2 className="account-title">
                    {entityName && (
                      <h2 className="account-title">{entityName}</h2>
                    )}
                  </h2>
                  {/* <h3 className="account-title">Login</h3> */}
                  <p className="account-subtitle">Access to our dashboard</p>
                  {/* Account Form */}
                  <div>
                    {loginError === true && (
                      <div className="alert alert-danger" role="alert">
                        {loginErrorMessage}
                      </div>
                    )}

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              id="login_email"
                              defaultValue={localStorage.getItem("email")}
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                id="login_password"
                                type={eye ? "password" : "text"}
                                defaultValue={localStorage.getItem("password")}
                                value={field.value}
                                onChange={field.onChange}
                                // autoComplete="true"
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            } `}
                          />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {errors.password?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                          id="login_btn"
                          disabled={loading}
                        >
                          {!loading ? `Login` : <ButtonLoader />}
                        </button>
                      </div>
                    </form>
                    <div className="account-footer">
                      {/* <p>
                        Don't have an account yet?{" "}
                        <Link to="/register">Register</Link>
                      </p> */}
                    </div>
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
