import React, { useEffect, useState } from "react";
import {
  lc39,
  landing,
  icon05,
  i1,
  i2,
  i3,
  i4,
  i5,
  i6,
  i7,
  i8,
  i9,
  i10,
} from "../../../Routes/ImagePath";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const styles = {
    landing: {
      backgroundImage: `url(${landing})`, // Set your background image here
      backgroundSize: "cover", // Ensures the image covers the entire element
      backgroundPosition: "center", // Centers the image
      backgroundRepeat: "no-repeat", // Prevents the image from repeating
      minHeight: "100vh", // Ensures the section covers the viewport
      paddingTop: "80px",
    },
    cardImage: {
      maxWidth: "60px",
      backgroundColor: "rgb(237 237 237)",
      borderRadius: "50%",
      padding: "7px",
    },
    sidebar: {
      width: "0px",
    },
    cheading: {
      paddingTop: "5px",
    },
    lanc: {
      display: "flex",
    },
    ccontent: {
      fontSize: "12px",
      paddingTop: "10px",
      color: "#747272",
    },
    card: {
      flex: 1,
      margin: "5px",
    },
    cardbdy: {
      padding: "15px",
    },
  };

  const redirectToLogin = () => {
    if (
      localStorage.getItem("isSubdomain") === "true" &&
      localStorage.getItem("entity_logo")
    ) {
      if(location.pathname === '/'){
        navigate('/login');
      }
      
    }
    setTimeout(() => {
      if (!localStorage.getItem("entity_name")) {
        redirectToLogin();
      }
    }, 100);
  };
  useEffect(() => {
    if (localStorage.getItem("entity_name")) {
      redirectToLogin();
    }
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div style={styles.landing}>
        {/* Page Content */}
        <Header />
        <div className="content container-fluid pb-0">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 style={{ color: "#fff", marginBottom: "5px" }}>Welcome! </h2>
              <p style={{ color: "#fff", marginBottom: "15px" }}>
                {" "}
                The TS Advantage Platform—your all-in-one solution for HR
                management, recruitment, client collaboration, and insights.
              </p>
            </div>
          </div>

          <div className="container-fluid">
            {" "}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="lanc" style={styles.lanc}>
                  <div className="card" style={styles.card}>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i1} style={styles.cardImage} />
                      <i className="fa fa-handshake-o" aria-hidden="true"></i>
                      <h6 style={styles.cheading}> Client On-Boarding </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        This section is to onboard New Client & Register their
                        entity to the platform. Set up the user account
                        including HR Admin User Account, and ensure that all
                        required steps are taken for a seamless start to your
                        collaboration.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card" style={styles.card}>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i2} style={styles.cardImage} />
                      <h6 style={styles.cheading}> HR Administrator </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        Manage all human resources functions with ease in this
                        centralized admin hub. Access employee records, payroll
                        information, attendance, and compliance details.
                        Configure HRMS settings, manage system users, and
                        generate reports to streamline your HR operations and
                        ensure organizational efficiency.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card ribbone-card" style={styles.card}>
                    <div className="ribbone ribbone-top-right text-primary">
                      <span className="bg-danger">New</span>
                    </div>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i3} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Talent Acquisition </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        The Talent Acquisition tab focuses on the recruitment
                        process, enabling HR teams to manage job postings,
                        applications, interviews, and candidate selections in a
                        streamlined way.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card" style={styles.card}>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i4} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Employee Login </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        A secure entry point for employees to access self
                        records, Policies, Employee Benefits, Policy Documents,
                        Attendance, Leave Application and many more. This is
                        your employee has personalized access to the relevant
                        information, Resources, and Company Information.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card" style={styles.card}>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i5} style={styles.cardImage} />
                      <h6 style={styles.cheading}>
                        {" "}
                        Pre-Boarding & On-Boarding{" "}
                      </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        Ensure a smooth transition for your new hires with
                        seamless pre-boarding and onboarding processes. From
                        document submission and background checks to training
                        schedules and orientation, manage everything in one
                        place. Enhance the candidate experience and set them up
                        for success from day one.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {" "}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="lanc" style={styles.lanc}>
                  <div className="card ribbone-card" style={styles.card}>
                    <div className="ribbone ribbone-top-left text-primary">
                      <span className="bg-secondary">Upcoming</span>
                    </div>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i6} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Delivery Platform </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        It provides Delivery Status real-time tracking of
                        ongoing deals & Project Deliveries, giving both
                        employees and clients visibility into the progress of
                        items i.e. Sales, Services and Projects.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card ribbone-card" style={styles.card}>
                    <div className="ribbone ribbone-top-left text-primary">
                      <span className="bg-secondary">Upcoming</span>
                    </div>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i7} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Compensation & Benefits </h6>
                      <p style={styles.ccontent}>
                        Effortlessly manage employee compensation and benefits.
                        Track payroll, tax deductions, and benefits utilization
                        while ensuring compliance. Provide employees with
                        transparency into their salary breakdown and perks,
                        fostering trust and satisfaction.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card ribbone-card" style={styles.card}>
                    <div className="ribbone ribbone-top-left text-primary">
                      <span className="bg-secondary">Upcoming</span>
                    </div>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i8} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Performance Management </h6>
                      <p style={styles.ccontent}>
                        Monitor and enhance employee performance with structured
                        goal tracking, real-time feedback, and performance
                        appraisals. Identify top performers, manage development
                        plans, and drive organizational success with data-driven
                        insights and analytics.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card ribbone-card" style={styles.card}>
                    <div className="ribbone ribbone-top-left text-primary">
                      <span className="bg-secondary">Upcoming</span>
                    </div>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i9} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Client Portal</h6>
                      <p style={styles.ccontent}>
                        A dedicated space for clients to manage their accounts,
                        view reports, track project progress, and communicate
                        with your team. Simplify collaboration and provide
                        clients with visibility into key metrics and
                        deliverables.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                  <div className="card ribbone-card" style={styles.card}>
                    <div className="ribbone ribbone-top-left text-primary">
                      <span className="bg-secondary">Upcoming</span>
                    </div>
                    <div
                      className="card-body text-center"
                      style={styles.cardbdy}
                    >
                      <img src={i10} style={styles.cardImage} />
                      <h6 style={styles.cheading}> Sales & Analytics </h6>
                      <p style={styles.ccontent}>
                        {" "}
                        This tab manage and track interactions with potential
                        and existing clients. It’s designed to centralize sales
                        activities, optimize customer relationships and drive
                        better sales performance.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  {/* Card End */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ color: "#fff" }}></hr>
        {/* /Page Content */}
        <Footer />
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default LandingPage;
