import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Table } from "antd";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import AddRoleModal from "../../../../components/Masters/Roles/AddRoleModal";
import EditRoleModal from "../../../../components/Masters/Roles/EditRoleModal";
import DeleteConfirmationModal from "../../../../components/modelpopup/DeleteConfirmationModal";
import { getApiUrl } from "../../../../utils/AuthUtils";
import {
  getStatusBadgeClass,
  hasPermissionBySubmoduleSlugs,
} from "../../../../utils/Misc"; // Importing constants

const Roles = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const currentUserRole = localStorage.getItem("role");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");
  const [showAddAction, setShowAddAction] = useState(false);
  const [showEditAction, setShowEditAction] = useState(false);
  const [showDeleteAction, setShowDeleteAction] = useState(false);

  useEffect(() => {
    const modules = JSON.parse(
      localStorage.getItem("userModulePermissions") || "[]"
    ); // Retrieve module from localStorage
    setShowAddAction(
      hasPermissionBySubmoduleSlugs(modules, ["roles"], "create")
    );
    setShowEditAction(
      hasPermissionBySubmoduleSlugs(modules, ["roles"], "update")
    );
    setShowDeleteAction(
      hasPermissionBySubmoduleSlugs(modules, ["roles"], "delete")
    );
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(getApiUrl() + `/api/masters/roles`, {
        params: {
          filter: filterText, // add filter if needed
          limit: pageSize,
          page: currentPage,
          order: sortingOrder,
          orderField: sortingColumn,
        },
      });
      let roleData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      roleData = roleData.map((item) => {
        return {
          ...item,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setRolesData(roleData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
    setCurrentPage(1);
  };

  const baseColumns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <div className="profile-split">{record.title}</div>
            </h2>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const badgeClass = getStatusBadgeClass(_.toLower(text));
        return <span className={badgeClass}>{_.capitalize(text)}</span>; // Return styled status badge
      },
    },
  ];

  const visibilityColumn = {
    title: "Visibility",
    dataIndex: "visibility",
    render: (text, record) => {
      return (
        <span className="btn btn-white btn-sm pe-none badge-outline-success status-color mx-1">
          {_.capitalize(text)}
        </span>
      ); // Return styled status badge
    },
  };

  const columns =
    currentUserRole === "super-admin"
      ? [
          baseColumns[0],
          visibilityColumn,
          baseColumns[1],
          baseColumns[2],
          baseColumns[3],
        ] // Insert extraColumn as the second column
      : baseColumns;
  if (showDeleteAction || showEditAction) {
    columns.push({
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            id="list-dropdown"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {showEditAction && (
              <>
                <Link
                  className="dropdown-item"
                  to="#"
                  id="list-edit-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setRoleId(record.id);
                    setEditModal(true);
                  }}
                >
                  <i className="fa fa-pencil m-r-5" /> Edit
                </Link>
                <Link
                  className="dropdown-item"
                  to={`/roles/dashboard-categories/${record.id}`}
                >
                  <i className="fas fa-tachometer-alt"></i> Dashboard Categories
                </Link>
              </>
            )}
            {showDeleteAction && record.is_predefined === 0 && (
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_modal"
                id="list-delete-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setRoleId(record.id);
                }}
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </Link>
            )}
          </div>
        </div>
      ),
    });
  }

  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Roles"
          title="Dashboard"
          subtitle="Roles"
          onAddClick={showAddAction ? () => setAddModal(true) : null}
          name="Add Role"
        />
        <hr />
        {/* /Search Filter */}
        <div className="filter-section filter-bottom">
          <ul>
            <li>
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <i className="las la-search" />
                  </Link>
                  <div className="dataTables_filter">
                    <label>
                      {" "}
                      <input
                        type="search"
                        id="search_input"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={handleSearchTextChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                dataSource={rolesData}
                rowKey={(record) => record.id}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalRecords,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: true, // Enables the page size selection dropdown
                  pageSizeOptions: ["10", "20", "50", "100"], // Options for the page size selection
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      {addModal && (
        <AddRoleModal
          handleUpdateList={handleUpdateList}
          closeModal={() => setAddModal(false)}
        />
      )}
      {editModal && (
        <EditRoleModal
          id={roleId}
          handleUpdateList={handleUpdateList}
          closeModal={() => setEditModal(false)}
        />
      )}
      <DeleteConfirmationModal
        method="patch" // Specify the HTTP method for deletion (e.g., 'delete', 'patch', etc.)
        url={`${getApiUrl()}/api/masters/roles/${roleId}`} // Specify the API endpoint for deletion
        itemName="Role" // Specify the name of the item being deleted
        handleUpdateList={handleUpdateList} // Specify a function to handle list update after deletion
      />
    </div>
  );
};

export default Roles;
